/* eslint-disable */
import React from "react"
import logo from "../images/logo-football-prenup-pdf.jpg"
import icon from "../images/icon-bww-logo-pdf.jpg"

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer"
import { PDFViewer } from "@react-pdf/renderer"
import ReactPDF from '@react-pdf/renderer';

import { connect } from "react-redux"

import Layout from "../components/layout"

import {
  getGamePairs,
  getTradeOffs,
  getFavoriteTeamName,
  getPartnerName,
  getFanName,
} from "../datastore/form-data"


const mapStateToProps = state => ({
    gamePairs: getGamePairs(state),
    tradeOffs: getTradeOffs(state),
    favoriteTeamName: getFavoriteTeamName(state),
    partnerName: getPartnerName(state),
    fanName: getFanName(state),
    
    // DUMMY DATA FOR LAYOUT DEVELOPMENT
    // fanName: "John Smith",
    // partnerName: "Jane Doe",
    // favoriteTeamName: "Atlanta Falcons",
    // gamePairs: [["", "Atlanta"], ["Liverpool","Everton"], ["Vikings", "Bulls"]],
    // tradeOffs: ["Laundry", "Yard work", "Sex"],
})


// Create styles
const styles = StyleSheet.create({
  viewer: {
    width: "100%",
    height: "90vh",
  },
  page: {
    flexDirection: "row",
    backgroundColor: "white",
  },
  section: {
    margin: 30,
    flexGrow: 1,
    paddingTop: 10,
    paddingBottom: 30,
    paddingHorizontal: 20,
    border: "2 solid #f4c34a",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexFlow: "row wrap",
  },
  col1: {
    width: "220",
    textAlign: "left",
    fontFamily: "Times-Roman",
  },
  col2: {
    width: "200",
    textAlign: "left",
    fontWeight: "ultrabold",
    fontFamily: "Times-Roman",
    borderBottom: "1 solid #000000",
    marginHorizontal: 20,
    padding: 0,
  },
  title: {
    fontSize: 18,
    textAlign: "center",
    fontFamily: "Oswald",
    marginBottom: 40,
  },
  headerTitle: {
    fontSize: 13,
    textAlign: "left",
    marginBottom: 3,
    marginLeft: 12,
  },
  headerTitleInput: {
    fontSize: 13,
    textAlign: "left",
    marginBottom: 3,
    marginLeft: 12,
    fontWeight: "ultrabold",
  },
  headerEntry: {
    fontSize: 14,
    textAlign: "left",
    marginBottom: 3,
    marginLeft: 12,
  },
  subtitle: {
    fontSize: 14,
    margin: 12,
    fontFamily: "Oswald",
    textAlign: "center",
    marginTop: 20,
  },
  articleTitle: {
    fontFamily: "Oswald",
    fontWeight: "ultrabold",
    fontSize: 11,
    marginLeft: 12,
    marginBottom: 0,
  },
  text: {
    margin: 11,
    marginTop: 3,
    marginBottom: 6,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  index1Step: {
    margin: 36,
    marginTop: 3,
    marginBottom: 6,
    fontSize: 11,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  index2Step: {
    margin: 48,
    marginTop: 3,
    marginBottom: 6,
    fontSize: 11,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  listItem: {
    margin: 12,
    marginTop: 3,
    marginBottom: 1,
    fontSize: 11,
    textAlign: "left",
    fontFamily: "Times-Roman",
  },
  listItemRow: {
    display: "flex",
    flexFlow: "row wrap",
  },
  disclaimer: {
    margin: 12,
    marginTop: 3,
    marginBottom: 3,
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Times-Italic",
  },
  image: {
    textAlign: "center",
    marginHorizontal: "auto",
    width: "125px",
    height: "113px",
    position: "absolute",
    top: 0,
    backgroundColor: "white",
    marginTop: 0,
    left: 244,
    border: "1 solid white",
  },
  icon: {
    textAlign: "center",
    marginHorizontal: "auto",
    width: "38px",
    height: "25px",
    position: "absolute",
    bottom: 19,
    backgroundColor: "white",
    marginTop: 0,
    right: 35,
    border: "1 solid white",
  },
  header: {
    fontSize: 11,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    height: 80,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 15,
    width: 30,
    right: 18,
    textAlign: "right",
    color: "grey",
    backgroundColor: "white",
    padding: 10,
  },
})

Font.register({
  family: "Oswald",
  src: "/fonts/Oswald-Bold.ttf",
})

class ErrorBoundary extends React.Component {
  componentDidCatch(error) {
    console.log(error);
  }

  render() {
    return this.props.children;
  }
}

// Create Document Component
export class MyDocument extends React.Component {
  constructor() {
    super()

    var today = new Date(),
      dateToday =
        (today.getMonth() + 1) +
        "-" +
        today.getDate() + 
        "-" +
        today.getFullYear()

    this.state = {
      date: dateToday,
    }
  }

  render() {
    const {
      fanName,
      partnerName,
      favoriteTeamName,
      tradeOffs,
      gamePairs,
    } = this.props

    const gamesList = gamePairs.map((gamePair, i) => {
      // console.log(gamePair)

      if (gamePair) {
        if (gamePair[0] != "" && gamePair[1] != "") {
          return (
            <View style={styles.col2} key={`gamePairRow-${i}`}>
              <Text style={styles.listItem} key={`gamePair-${i}`}>
                {gamePair[0] + " vs. " + gamePair[1]}
              </Text>
            </View>
          )
        }
      }

      // console.log("No games selected")
    })

    const tradeOffList = tradeOffs.map((tradeOff, i) => {
      // console.log(tradeOff)

      if (tradeOff !== "") {
        return (
          <View style={styles.col2} key={`tradeOffRow-${i}`}>
            <Text style={styles.listItem} key={`tradeOff-${i}`}>
              {tradeOff}
            </Text>
          </View>
        )
      }
    })

    return (
      <Document>
        {/* PAGE 1 */}
        <Page size="Letter" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.header}> </Text>
            <View style={styles.row}>
              <View style={styles.col1}>
                <Text style={styles.headerTitle}>AGREEMENT DATE</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.headerTitleInput}>{this.state.date}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.col1}>
                <Text style={styles.headerTitle}>NAME OF FOOTBALL FAN</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.headerTitleInput}>{fanName}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.col1}>
                <Text style={styles.headerTitle}>
                  NAME OF PARTNER (NON-FAN)
                </Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.headerTitleInput}>{partnerName}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.col1}>
                <Text style={styles.headerTitle}>FAN’S FAVORITE TEAM</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.headerTitleInput}>{favoriteTeamName}</Text>
              </View>
            </View>
            <Text style={styles.subtitle}>DECLARATION</Text>
            <Text style={styles.text}>
              Be it officially known that {"___" + fanName + "___"} (“the Fan”)
              and
              {"___" + partnerName + "___"} (“the Partner”), hereby enter into
              the following legally binding
              <Text style={{ fontFamily: "Times-Italic" }}>[1]</Text> agreement,
              thereby enabling the Fan to watch or attend a select list of
              preferred football games, heretofore specified by the Fan, in
              exchange for a select list of trade-offs, heretofore specified by
              the Partner. This legally binding agreement
              <Text style={{ fontFamily: "Times-Italic" }}>[2]</Text> shall be
              in effect through the duration of the 2019 football season.
              <Text style={{ fontFamily: "Times-Italic" }}>[3]</Text>
            </Text>
            <Text style={styles.disclaimer}>[1] Not legally binding.</Text>
            <Text style={styles.disclaimer}>
              [2] Again, not legally binding, we’re a sports bar.
            </Text>
            <Text style={styles.disclaimer}>
              [3] Seriously, not legal; if you go to a sports bar for legal
              documents you’ve got bigger problems.
            </Text>
            <Text style={styles.subtitle}>AGREEMENTS</Text>

            <Text style={styles.articleTitle}> </Text>
            <Text style={styles.articleTitle}>ARTICLE I</Text>
            <Text style={styles.articleTitle}>CONTINGENT AGREEMENT</Text>
            <Text style={styles.text}>
              This agreement is contingent upon the Fan’s interest in
              watching/attending the 2019 football season, while having a
              relationship with someone who actually cares.<Text style={{ fontFamily: "Times-Italic" }}>[4]</Text> This person,
              referred to as the Partner, must have an express interest in a
              relationship with the Fan extending through or beyond the season,
              with a willingness to enter into this agreement; any Fan who just
              makes this agreement and is like “You have to let me watch all the
              football now” shall be deemed in need of serious relationship
              help.
            </Text>
            <Text style={styles.text}>
              In the unlikely event that the 2019 football season does not
              occur, the Fan does not express an interest in football,<Text style={{ fontFamily: "Times-Italic" }}>[5]</Text> or the
              Partner does not wish to enter into the agreement, then don’t fill
              this out because there’s no point.
            </Text>
            <Text style={styles.disclaimer}>[4] Ouch?</Text>
            <Text style={styles.disclaimer}>
              [5] Why are you even reading this then? Get a hobby.
            </Text>
          </View>
          <Image style={styles.image} src={logo} />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber }) => `${pageNumber}`}
          />
          <Image style={styles.icon} src={icon} />
        </Page>

        {/* PAGE 2 */}
        <Page size="Letter" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.articleTitle}> </Text>
            <Text style={styles.articleTitle}>ARTICLE II</Text>
            <Text style={styles.articleTitle}>FAN PROTECTIONS</Text>
            <Text style={styles.text}>
              I. During the specified protected games, lasting from kickoff to
              end of game, the Fan shall not be subjected to interruption or
              distraction, including but not limited to the following:
            </Text>
            <Text style={styles.index1Step}>
              A. Guilt in the form of comments, texts, snark, reminders of past
              mistakes, etc.
            </Text>
            <Text style={styles.index1Step}>
              B. Household chores: dishes, laundry, clutter management, cleaning
              stuff, etc.
            </Text>
            <Text style={styles.index1Step}>
              C. Errands: furniture stores, clothing stores, hardware stores,
              pretty much all stores.
            </Text>
            <Text style={styles.index1Step}>
              D. Social interactions where the Fan must form words or phrases in
              conversations not related to football.
            </Text>
            <Text style={styles.index1Step}>
              E. Parties: birthdays, kids birthdays, baby showers, bachelor/ette
              parties, gender reveal parties, MLM<Text style={{ fontFamily: "Times-Italic" }}>[6]</Text> parties or the like.
            </Text>
            <Text style={styles.text}>
              II. The Fan retains the right to engage in an interruption should
              it be something kinda cool.
            </Text>
            <Text style={styles.disclaimer}>
              [6] Protection against multi-level marketing (MLM) parties shall
              extend into perpetuity, because if you invite me to one of these,
              we’re not real friends.
            </Text>
            <Text style={styles.articleTitle}> </Text>
            <Text style={styles.articleTitle}>ARTICLE III</Text>
            <Text style={styles.articleTitle}>FAN: PROTECTED GAMES</Text>
            <Text style={styles.text}>I. Games played by Favorite Team</Text>
            <Text style={styles.index1Step}>
              A. The Fan shall watch all regular season games played by{" "}
              {"___" + favoriteTeamName + "___"}.
            </Text>
            <Text style={styles.text}>II. Additional Protected Games</Text>
            <Text style={styles.index1Step}>
              A. The Fan may protect additional games not played by{" "}
              {"___" + favoriteTeamName + "___"} but which the Fan deems “super
              important,” by listing said games in the “Addendum.”
            </Text>
            <Text style={styles.index1Step}>
              B. The Fan shall not list tons of games because, seriously, just
              pick your favorites.
            </Text>
            <Text style={styles.articleTitle}> </Text>
            <Text style={styles.articleTitle}>ARTICLE IV</Text>
            <Text style={styles.articleTitle}>PARTNER: GAME TRADE-OFFS</Text>
            <Text style={styles.text}>
              I. The Partner shall determine and receive trade-offs in the form
              of favors, gifts, deeds and/or make-goods for each protected game
              listed by the Fan.
            </Text>
            <Text style={styles.text}>
              II. Trade-offs can be anything so long as they are mutually agreed
              upon and are not: illegal (e.g., steal me a car), incredibly
              stupid (e.g., eat 20 burritos at once), insensitive (e.g., not
              going to give you ideas here, just use your brain), impossible
              (e.g., invent time travel), impossible AND stupid (e.g., invent
              time travel to steal a baby t-rex), or things you should be doing
              anyway (e.g., dishes, child care, listening to you, showering).
            </Text>
            <Text style={styles.text}>
              III. The type, variety, and quantity of each favor shall be set
              exclusively by the Partner.
            </Text>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber }) => `${pageNumber}`}
            fixed
          />
          <Image style={styles.icon} src={icon} />
        </Page>

        {/* PAGE 3 */}
        <Page size="Letter" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.text}>
              IV. Requests can include but are definitely not limited to the
              following:
            </Text>
            <Text style={styles.index1Step}>
              A. Physical touch: Massages, high fives, sexy time, sexy time
              followed by high fives, etc.
            </Text>
            <Text style={styles.index1Step}>
              B. Quality time: Date nights, road trips, couples therapy,
              unbroken eye contact, etc.
            </Text>
            <Text style={styles.index1Step}>
              C. Kind words: Compliments, love notes, odes to you on social
              media, etc.
            </Text>
            <Text style={styles.index1Step}>
              D. Gifts: Jewelry, electronics, electronic jewelry, the latest
              “thing,” etc.
            </Text>
            <Text style={styles.index1Step}>
              E. Services: Fix something, break something, sexy time (see
              “physical touch”).
            </Text>
            <Text style={styles.text}>
              V. Partner may list a single, recurring trade-off or a variety,
              and may specify the total time, quantity, and number of
              recurrences.
            </Text>
            <Text style={styles.text}>LIST OF TRADE-OFFS</Text>
            <View style={styles.listItemRow}>{tradeOffList}</View>
            <Text style={styles.articleTitle}> </Text>
            <Text style={styles.articleTitle}>ARTICLE V</Text>
            <Text style={styles.articleTitle}>
              WATCHING: BROADCAST VS IN-PERSON
            </Text>
            <Text style={styles.text}>I. Broadcast</Text>
            <Text style={styles.index1Step}>
              A. Should the Fan watch games via TV or Internet broadcast, the
              fan reserves the right to watch at least 15 minutes of pre- and
              post-game coverage.
            </Text>
            <Text style={styles.index1Step}>
              B. Fan may waive pre- and post-game coverage should commentators
              be lame or their team lose resulting in the Fan being really
              bummed out.
            </Text>
            <Text style={styles.text}>II. In Person</Text>
            <Text style={styles.index1Step}>
              A. Should the Fan score tickets, travel time to and from the venue
              is protected, along with a 20-minute buffer to find seats, use the
              bathroom, check out the fan store, get food, complain about the
              prices, get lost, then find the seats again.
            </Text>
            <Text style={styles.text}>
              III. Tailgates and Post-game Celebrations
            </Text>
            <Text style={styles.index1Step}>
              A. Pre-game tailgates and post-game celebrations must be cleared
              by the Partner and matched with an additional trade-off should
              they last more than one (1) hour.
            </Text>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber }) => `${pageNumber}`}
            fixed
          />
          <Image style={styles.icon} src={icon} />
        </Page>

        {/* PAGE 4 */}
        <Page size="Letter" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.articleTitle}> </Text>
            <Text style={styles.articleTitle}>ARTICLE VI</Text>
            <Text style={styles.articleTitle}>PARTNER AUDIBLES</Text>
            <Text style={styles.text}>
              I. Should an unexpected life event occur that requires immediate
              attention, the Partner reserves the right to “call an audible” and
              cancel the protected game.<Text style={{ fontFamily: "Times-Italic" }}>[7]</Text>
            </Text>
            <Text style={styles.index1Step}>
              A. Acceptable examples of unexpected life events include:
            </Text>
            <Text style={styles.index2Step}>
              1. Fan or Partner goes into labor and it’s still the first half<Text style={{ fontFamily: "Times-Italic" }}>[8]</Text>
            </Text>
            <Text style={styles.index2Step}>
              2. Last-minute elopement by a close relative or best friend
            </Text>
            <Text style={styles.index2Step}>
              3. You or your location is suddenly on fire and not in the good
              way
            </Text>
            <Text style={styles.index1Step}>
              B. Unacceptable examples of unexpected life events:
            </Text>
            <Text style={styles.index2Step}>1. Mad at the Fan</Text>
            <Text style={styles.index2Step}>2. There’s a spider kill it</Text>
            <Text style={styles.index2Step}>
              3. Dude there’s a fight outside<Text style={{ fontFamily: "Times-Italic" }}>[9]</Text>
            </Text>
            <Text style={styles.disclaimer}>
              [7] Some things are more important than a football game. Don’t
              EVER tell anyone we said that.
            </Text>
            <Text style={styles.disclaimer}>
              [8] After first half, there’s probably time to finish the game
              before the baby comes.
            </Text>
            <Text style={styles.disclaimer}>
              [9] You might want to check it out though.
            </Text>
            <Text style={styles.articleTitle}> </Text>
            <Text style={styles.articleTitle}>ARTICLE VII</Text>
            <Text style={styles.articleTitle}>RENEGOTIATION</Text>
            <Text style={styles.text}>I. “TANKING”</Text>
            <Text style={styles.index1Step}>
              A. Should the Fan’s favorite team totally suck it up to the level
              of personal embarrassment to the Fan due to injuries, trades, or
              general lame-ass gameplay, leading to a disinterest in watching
              future games, the Fan reserves the right to swap or opt out of
              future game agreements, subject to a penalty.
            </Text>
            <Text style={styles.articleTitle}>
              TANKING CANCELLATION PENALTY CLAUSE
            </Text>
            <Text style={styles.text}>
              Should the Fan wish to swap games or cancel all or a portion of
              the agreement due to the Fan’s team totally shitting the bed, the
              Partner retains the right to rub it in the Fan’s face and hold
              said Fan accountable for up to 20% of the remaining trade-offs for
              any unwatched games. Should the Fan have a bad attitude about it,
              the Partner may utilize the “You did this to yourself” defense,
              the “Don’t hate the player, hate the game” logic, or the “True
              fans would watch anyway” guilt trip.
            </Text>
            <Text style={styles.text}>
              II. “UNEXPECTED AWESOMENESS” Should a team go on a freaking tear
              and unexpectedly be a lot more fun to watch, the Fan reserves the
              right to renegotiate this agreement to add more games (see “Come
              on, this is seriously gonna be a huge game” clause).
            </Text>
            <Text style={styles.articleTitle}>
              COME ON, THIS IS SERIOUSLY GONNA BE A HUGE GAME CLAUSE
            </Text>
            <Text style={styles.text}>
              In the event that a game is deemed “Seriously gonna be a huge
              game” by the Fan, supported by the general public, sports pundits,
              and/or an unbiased mediator, the Partner must make provisions for
              the Fan to watch/attend said game and provide appropriate
              trade-offs.
            </Text>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber }) => `${pageNumber}`}
            fixed
          />
          <Image style={styles.icon} src={icon} />
        </Page>

        {/* PAGE 5 */}
        <Page size="Letter" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.articleTitle}> </Text>
            <Text style={styles.articleTitle}>ARTICLE VIII</Text>
            <Text style={styles.articleTitle}>TERMINATION CLAUSES</Text>
            <Text style={styles.text}>
              I. PREMATURE OFFICIAL TERMINATION OF A SEASON
            </Text>
            <Text style={styles.index1Step}>
              A. Should the football season end prematurely for any unforeseen
              reason, this agreement shall be null and void. Reasons may include
              but are not limited to:
            </Text>
            <Text style={styles.index2Step}>
              1. Union strike: Player, referee, broadcaster, ownership,<Text style={{ fontFamily: "Times-Italic" }}>[10]</Text> etc.
            </Text>
            <Text style={styles.index2Step}>
              2. National emergency: War, famine, everyone just kinda sad, etc.
            </Text>
            <Text style={styles.index2Step}>
              3. Apocalypse comes upon us all, bringing an end to the human race
              and subsequently, football, in the form of zombies, nuclear
              winter, pissed aliens, four horsemen, big dude snaps fingers,
              software glitch, or the like.
            </Text>
            <Text style={styles.disclaimer}>
              [10] We don’t want to live in a world where owners go on strike,
              would prefer the zombies.
            </Text>
            <Text style={styles.text}>
              II. PREMATURE UNOFFICIAL TERMINATION OF A SEASON ‘CUZ YOUR TEAM
              SUCKS
            </Text>
            <Text style={styles.index1Step}>
              A. Should the Fan declare, at any point in the season, due but not
              limited to the disappointing performance of favorite team(s), that
              they now hate football and throw item(s) across the room such as a
              drink, pillow, TV remote, TV, or any other item heftable by an
              average-sized emotional fan, the statement may be retracted by
              said Fan after a cooling off period of 1-3 days.
            </Text>
            <Text style={styles.text}>
              III. PREMATURE OR LONG OVERDUE TERMINATION OF A RELATIONSHIP
            </Text>
            <Text style={styles.index1Step}>
              A. Should the relationship between {"___" + fanName + "___"} and
              {"___" + partnerName + "___"} end during the course of the season,
              be it suddenly or long-time coming, be it due to loss of affection
              or connection, consistent arguing, physical or emotional cheating,
              one or both parties needing to find one’s self,<Text style={{ fontFamily: "Times-Italic" }}>[11]</Text> or the like,
              the agreement shall be null and void, and Buffalo Wild Wings shall
              not be held responsible.<Text style={{ fontFamily: "Times-Italic" }}>[12]</Text>
            </Text>
            <Text style={styles.index1Step}>
              B. Relationships ended by text, emoji, or ghosting as to avoid an
              awkward and potentially heated situation shall be open for appeal
              by the dumped party. Relationships ended in this manner shall be
              deemed total bullshit, and hence the dumped party reserves the
              right to hate you forever.
            </Text>
            <Text style={styles.disclaimer}>
              [11] “I need to find myself ” actually means “I need to find
              someone else.”
            </Text>
            <Text style={styles.disclaimer}>
              [12] If your relationship ends over this, you honestly had it
              coming.
            </Text>
            <Text style={styles.articleTitle}> </Text>
            <Text style={styles.articleTitle}>ARTICLE IX</Text>
            <Text style={styles.articleTitle}>LEGALITY AND ENFORCEABILITY</Text>
            <Text style={styles.text}>
              I. Buffalo Wild Wings is a sports bar and restaurant, not a law
              office, so clearly this isn’t legally binding, but our lawyers
              want us to make this EXTRA clear, so here it is one last time.
              This is not legally binding or enforceable. If you think this is
              legal just because a free-roaming-large-mammal-themed sports
              restaurant promoted it on your feed, you kind of deserve whatever
              the consequences are.
            </Text>
            <Text style={styles.articleTitle}> </Text>
            <Text style={styles.articleTitle}>ARTICLE X</Text>
            <Text style={styles.text}>
              I. Signatures of both the Fan and the Partner constitute an
              official agreement for the duration of the 2019 football season.
              In signing, both parties acknowledge that they are signing of
              their own will and volition. Any signature gained under fraud,
              duress, manipulation, drunkenness or unconsciousness shall void
              the agreement.
            </Text>
            <Text style={styles.text}>
              (Signature of The Fan)____________________________________ Date
              ________________
            </Text>
            <Text style={styles.text}>
              (Signature of Partner, or “Non-Fan”)_________________________ Date
              ________________
            </Text>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber }) => `${pageNumber}`}
            fixed
          />
          <Image style={styles.icon} src={icon} />
        </Page>

        {/* PAGE 5 */}
        <Page size="Letter" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.articleTitle} break>
              ADDENDUM
            </Text>
            <Text style={styles.articleTitle}>
              ADDITIONALLY PROTECTED GAMES
            </Text>
            <View style={styles.listItemRow}>{gamesList}</View>
            <Text style={styles.articleTitle}> </Text>
            <Text style={styles.text}>
              I. The Fan may also list additional games to the back of this
              document.
            </Text>
            <Text style={styles.text}>
              II. The Fan may list postseason games up to three (3) days before
              the start of each postseason game.
            </Text>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber }) => `${pageNumber}`}
            fixed
          />
          <Image style={styles.icon} src={icon} />
        </Page>
      </Document>
    )
  }
}

const PrenupPDFComponent = props => {
  if (typeof window === 'undefined') {
    return null
  } else {
    return (
      <ErrorBoundary>
        <Layout>
          <PDFViewer style={styles.viewer}>
            <MyDocument {...props} />
          </PDFViewer>
          <div style={{ textAlign: "center" }}>
            <button className="btn btn-draft">
              <a
                href="/"
              >
                Back to BWW Prenup
            </a>
            </button>
          </div>
        </Layout>
      </ErrorBoundary>
    )
  }
}

const PrenupPDF = connect(mapStateToProps)(PrenupPDFComponent);

export default PrenupPDF
